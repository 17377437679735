<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <div class="auth-wrapper auth-v2">

      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link
          class="brand-logo"
        >
          <div>
            <td style="vertical-align: middle;">
              <logo
                style="height: 50px; width: auto; margin-top: 0.15rem;"
              />
            </td>
            <td style="vertical-align: middle;">
              <h2
                class="brand-text text-primary"
                style="margin-top: 7px; margin-left: 0.5rem; text-align: center;"
              >
                {{ appName }}
              </h2>
            </td>
          </div>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              :alt="$t('Register')"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Register-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-2"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title class="mb-1">
              <h2>{{ $t('MessageRegister.Title') }}</h2>
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('MessageRegister.Text') }}
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="registerForm"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >
                <!-- name -->
                <b-form-group
                  :label="$t('Name')"
                  label-for="register-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Name')"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      ref="input_name"
                      v-model="registerData.name"
                      name="register-name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- address -->
                <b-form-group
                  :label="$t('Address')"
                  label-for="register-address"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Address')"
                    vid="address"
                  >
                    <b-form-input
                      id="register-address"
                      v-model="registerData.address"
                      name="register-address"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Address')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- mobile -->
                <b-form-group
                  :label="$t('Mobile')"
                  label-for="register-mobile"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Mobile')"
                    vid="mobile"
                    rules="required"
                  >
                    <b-form-input
                      id="register-mobile"
                      ref="input_mobile"
                      v-model="registerData.mobile"
                      name="register-mobile"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Mobile')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group
                  :label="$t('Email')"
                  label-for="register-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Email')"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      ref="input_email"
                      v-model="registerData.email"
                      name="register-email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="user@email.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group
                  label-for="register-password"
                  :label="$t('Password')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Password')"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password"
                        ref="input_password"
                        v-model="registerData.password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="registerData.status"
                    name="checkbox-1"
                  >
                    {{ $t('I agree to') }}
                    <b-link>{{ $t('privacy policy & terms') }}</b-link>
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  type="submit"
                >
                  {{ $t('Sign up') }}
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <span>{{ $t('Already have an account?') }}</span>
              <b-link :to="{name:'auth-login'}">
                <span>&nbsp;{{ $t('Sign in instead') }}</span>
              </b-link>
            </p>

          </b-col>
        </b-col>
        <!-- /Register-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { $themeConfig } from '@themeConfig'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Logo from '@/@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg,
  BCardTitle,
  BCardText,
  BOverlay,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showOverlay: false,
      registerData: {
        name: '',
        address: '',
        mobile: '',
        email: '',
        password: '',
        status: false,
      },
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      skin: store.state.appConfig.layout.skin,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    localize(this.$i18n.locale)
  },
  setup() {
    const { appName } = $themeConfig.app

    return {
      appName,
    }
  },
  methods: {
    register() {
      this.$refs.registerForm.validate()
        .then(success => {
          if (success) {
            if (this.registerData.status === true) {
              this.$swal({
                title: this.$i18n.t('Are you sure?'),
                text: this.$i18n.t('Do you confirm your registration?'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$i18n.t('Yes, register it'),
                cancelButtonText: this.$i18n.t('Cancel'),
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.value === true) {
                  this.showOverlay = true
                  useJwt.register({
                    data: this.registerData,
                    token: 'IhIzDUzeuRluwXfUGVRfBxoVAGwBAGj7',
                  })
                    .then(response => {
                      this.showOverlay = false
                      useJwt.setToken(response.data.accessToken)
                      useJwt.setRefreshToken(response.data.refreshToken)
                      localStorage.setItem(`${$themeConfig.app.commonSession}-user-data`, JSON.stringify(response.data.userData))
                      this.$ability.update(response.data.userData.ability)
                      this.$router.push('/')
                    })
                    .catch(error => {
                      this.$refs.registerForm.setErrors(error.response.data.error)
                      const { response, message } = error
                      if (response) {
                        let errorMsg = this.$i18n.t(response?.data.error)
                        if (errorMsg === '') {
                          errorMsg = this.$i18n.t(response?.data.message)
                        }
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: errorMsg,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                          },
                        },
                        {
                          position: 'bottom-right',
                        })
                      } else if (message) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: error?.message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                          },
                        },
                        {
                          position: 'bottom-right',
                        })
                      }
                      this.showOverlay = false
                    })
                }
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$i18n.t('Please consent allow for privacy policy & terms'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
              })
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (this.registerData.name === '') {
              this.$refs.input_name.focus()
            } else if (this.registerData.mobile === '') {
              this.$refs.input_mobile.focus()
            } else if (this.registerData.email === '') {
              this.$refs.input_email.focus()
            } else if (this.registerData.password === '') {
              this.$refs.input_password.focus()
            }
          }
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
